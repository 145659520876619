import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const HomeFormOne = ({
  status,
  message,
  onValidated,
  setErrorState,
  setSucessState,
  setLoadingState,
}) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  return (
    <form
      className=""
      onSubmit={(e) => handleSubmit(e)}
    >
        {status === "sending" &&
          (setLoadingState(true), setSucessState(false), setErrorState(false))}
        {status === "error" &&
          (setErrorState(true), setLoadingState(false), setSucessState(false))}
        {status === "success" &&
          (setSucessState(true), setLoadingState(false), setErrorState(false))}
        
        {status !== "success" ? (
          <div className="mt-12 sm:flex sm:w-full sm:max-w-lg">
            <div className="min-w-0 flex-">
              <input
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
                placeholder="Enter your email"
                isRequired
                className="block w-full rounded-md border border-gray-300 px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-rose-500 focus:ring-rose-500"
              />
            </div>

            <div className="mt-4 sm:mt-0 sm:ml-3">
              <button
                type="submit"
                label="subscribe"
                className="animate-bounce block w-full rounded-md border border-transparent bg-rose-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10"
              >
                Add Me
              </button>
            </div>
          </div>
        ) : null}
    </form>
  );
};

const MailchimpForm1 = (props) => {
  const url = `https://gmail.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&amp;id=${process.env.REACT_APP_MAILCHIMP_ID}&amp;f_id=0021c7e1f0`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <HomeFormOne
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            setSucessState={props.setSucessState}
            setErrorState={props.setErrorState}
            setLoadingState={props.setLoadingState}
          />
        )}
      />
    </div>
  );
};
export { HomeFormOne, MailchimpForm1 };


// <!-- Begin Mailchimp Signup Form -->
// <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
// <style type="text/css">
// 	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;}
// 	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
// 	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
// </style>
// <div id="mc_embed_signup">
//     <form action="https://gmail.us21.list-manage.com/subscribe/post?u=3c01046be0b4ef84e06decabd&amp;id=4ffa2ba57f&amp;f_id=0021c7e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
//         <div id="mc_embed_signup_scroll">
//         <h2>Subscribe</h2>
//         <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
// <div class="mc-field-group">
// 	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
// </label>
// 	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
// 	<span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
// </div>
// 	<div id="mce-responses" class="clear foot">
// 		<div class="response" id="mce-error-response" style="display:none"></div>
// 		<div class="response" id="mce-success-response" style="display:none"></div>
// 	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
//     <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_3c01046be0b4ef84e06decabd_4ffa2ba57f" tabindex="-1" value=""></div>
//         <div class="optionalParent">
//             <div class="clear foot">
//                 <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
//                 <p class="brandingLogo"><a href="http://eepurl.com/ic-ygT" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"></a></p>
//             </div>
//         </div>
//     </div>
// </form>
// </div>
// <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
// <!--End mc_embed_signup--></link>