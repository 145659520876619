export default function ArticleBodyJanTwentyFirst2023() {
  return (
    <div className="relative overflow-hidden py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div
          className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-rose-600">
              Introducing
            </span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Web Devlopment Workshop
            </span>
          </h1>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            STEMEPA will host an online workshop on the fundamentals of web
            development! A computer is necassary and we would request only ages
            12+ to particpate. For more information read below! Sign up using
            the form below!
          </p>
          <br></br>
          <iframe
            width="100%"
            height="480"
            frameborder="0"
            title="Web Dev Sign Up"
            src="https://app.livestorm.co/p/16f1d39d-ce9d-49a1-8046-c4b49c990936/form"
          ></iframe>
        </div>
        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
          <p>
            Web development plays a crucial role in today's digital world. As it
            is defined, web development involves the creation and maintenance of
            websites and web applications like amazon, instagram, google, etc.,
            which are essentially the building blocks of the internet. Without
            web development, the internet as we know it would not exist.
          </p>
          <br></br>
          <p>
            One of the main reasons why web development is so important is
            because it allows businesses to have an online presence. In today's
            world, it is essential for businesses to have a website in order to
            reach a wider audience and attract potential customers. A
            well-designed and functional website can help a business stand out
            in a crowded online market and establish credibility. In addition,
            this is particularly important in the current globalized economy,
            where companies often operate in multiple countries and need to be
            able to communicate with customers in different languages and time
            zones. Overall, web development has created countless job
            opportunities, both directly and indirectly, and has contributed to
            the expansion of industries such as e-commerce, online education,
            and digital marketing.
          </p>
          <br></br>
          <p>
            Web development is also important for individuals who want to share
            their ideas, thoughts, and creations with the world. A personal
            website or blog can be a great way to express oneself, connect with
            like-minded people, and in some cases, show off coding skills to
            future employers.
          </p>
          <br></br>
          <p>
            Web development is constantly evolving and there is always something
            new to learn. This makes it an exciting and dynamic field to be a
            part of. With the increasing reliance on the internet for
            communication, information, and entertainment, the importance of web
            development is only set to grow in the future.
          </p>
          <br></br>
          {/* <div className="object-scale-down">
            <figure>
              <img
                className="w-full rounded-lg"
                src="./assets/webDevWorkshopFlyer.jpg"
                alt="Web Development Workshop"
              />
              <figcaption>
                Sagittis scelerisque nulla cursus in enim consectetur quam.
              </figcaption>
            </figure>
          </div> */}

          <br></br>
          <h2 className=" font-semibold text-rose-600">Why is this course important?</h2>
          <br></br>
          <p>
            Learning the basics of web development can be a useful skill for
            individuals and businesses alike. For young individuals, learning
            web development at a young age can help to give students a
            competitive edge in the job market. With more and more businesses
            moving online, web development skills are becoming increasingly
            valuable. By learning these skills at a young age, students can set
            themselves apart from their peers and be well-prepared for the job
            market.
          </p>
          <br></br>
          <p>
            Secondly, the demand for web developers is extremely high and having
            a strong foundation in web development can open up a range of job
            opportunities. According to the U.S. Bureau of Labor Statistics,
            employment of web developers is projected to grow 23% from 2021 to
            2031, much faster than the average for all occupations. This high
            demand means that those with web development skills will likely have
            a range of job opportunities available to them.
          </p>
          <br></br>
          <p>
            Third, learning web development can also be a great way for young
            people to learn problem-solving and critical-thinking skills.
            Furthermore, understanding the basics of web development can be a
            stepping stone to a career in the tech industry. Overall, a lesson
            on the basics of web development can provide a solid foundation for
            those looking to enter the tech industry or improve their
            understanding of how websites work
          </p>
          <br></br>
          <p>
            https://www.bls.gov/ooh/computer-and-information-technology/web-developers.htm
          </p>
        </div>
      </div>
    </div>
  );
}
