export default function LogoCloud() {
  return (
    <div className="-mt-24">
      <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          {/* Bucks County Library */}
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src="./assets/Partners/Bucks County Library.jpg" alt="Tuple" />
          </div>
         {/* Stemist Hacks */}
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src="./assets/Partners/stemist hacks.webp" alt="StaticKit" />
          </div>
          {/* Devpost */}
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src="./assets/Partners/devpost.svg" alt="StaticKit" />
          </div>
          {/* Echo 3D */}
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src="./assets/Partners/echo3D.png" alt="StaticKit" />
          </div>
          {/* Sticker Giant */}
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src="./assets/Partners/sticker giant.png" alt="StaticKit" />
          </div>
{/*           
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <img
              className="h-12"
              src="./public/assets/Partners/devpost.png"
              alt="Transistor"
            />
          </div>
          <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
            <img
              className="h-12"
              src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg"
              alt="Workcation"
            />
          </div> */}
        </div>
      </div>
    </div>
  )
}
