export default function ArticleBodyJuneTwentith2023() {
  return (
    <div className="relative overflow-hidden py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div
          className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-rose-600">
              Rising to the Challenge:
            </span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Pennsylvania Science and Innovation Competition Inspires
              Innovative Solutions
            </span>
      
          </h1>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            The Pennsylvania Science and Innovation Competition (PSIC) is an
            annual regional science research competition organized by STEM
            Enrichment PA. Targeted towards students in 7th to 12th grade, PSIC
            provides a platform for budding scientists to showcase their
            independent research and engage with the broader scientific
            community. To register click <a href="https://docs.google.com/forms/d/e/1FAIpQLSchERHwU5QLvr1szisrnpq65Kmndyvjc7JlQd4CuggUMssWWA/viewform?embedded=true" className="underline">here</a> or fill out embedded form below. More information about competition is bellow the form. 
          </p>
          <p className="underline bold text-lg">PRIZES TBD</p>
          <iframe title="Embeded registration form, link at bottom" src="https://docs.google.com/forms/d/e/1FAIpQLSchERHwU5QLvr1szisrnpq65Kmndyvjc7JlQd4CuggUMssWWA/viewform?embedded=true" width="640" height="1555" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
          <p>
            The theme for the upcoming 2023-2024 PSIC is "Rising to the
            Challenge," encouraging participants to address and overcome
            significant challenges in various fields. Participants are tasked
            with identifying pressing challenges related to their areas of
            interest, such as social issues, technological hurdles,
            environmental concerns, or health-related problems.
          </p>
          <br></br>
          <p>
            To excel in the competition, students are encouraged to develop
            innovative approaches and ideas that go beyond conventional
            solutions. Thinking outside the box and offering unique insights is
            key to tackling the identified challenges effectively. Additionally,
            participants must consider the feasibility of their proposed
            research projects, taking into account available resources, timing,
            and potential limitations. It is crucial to outline a realistic plan
            that can be implemented to address the chosen challenge.
          </p>
          <br></br>
          <p>
            Methodology plays a vital role in the PSIC research projects.
            Participants are required to determine appropriate data collection
            techniques, experimental designs, or theoretical frameworks that
            will help them effectively address the identified challenges. A
            well-thought-out and robust methodology is essential to produce
            credible and impactful results.
          </p>
          <br></br>
          <p>
            The PSIC timeline includes various important dates, such as the
            opening of applications on June 20th, early registration by July
            21st, final deadline on August 31st, progress check on September
            8th, and the conference/competition day on September 16th. Winners
            and honorable mentions will be announced on September 24th,
            recognizing the outstanding contributions made by the participants.
          </p>
          <br></br>
          {/* <div className="object-scale-down">
            <figure>
              <img
                className="w-full rounded-lg"
                src="./assets/webDevWorkshopFlyer.jpg"
                alt="Web Development Workshop"
              />
              <figcaption>
                Sagittis scelerisque nulla cursus in enim consectetur quam.
              </figcaption>
            </figure>
          </div> */}

          {/* <br></br>
            <h2 className=" font-semibold text-rose-600">Why is this course important?</h2> 
          <br></br> */}
          <p>
            With its focus on inspiring innovative solutions, the Pennsylvania
            Science and Innovation Competition provides a valuable opportunity
            for young scientists to showcase their talents, address significant
            challenges, and contribute to the advancement of science and
            technology. Subject: Sponsorship Opportunity: Support Rising Young
            Scientists at the Pennsylvania Science and Innovation Competition
            (PSIC)
          </p>
          <br></br>
          <h2 className=" font-semibold text-rose-600">Important Resources:</h2> 
          <ol>
              <li>< a href="https://docs.google.com/document/d/191af3X19TTOULj-ImdceYsk5sQrvmO20iTiSzs6mS7c/edit?usp=sharing" >Outline and Requriments click <u>here</u></a> </li>
              <li><a href="/link-broken">smaple presentation click <u>here</u></a></li>
          </ol>
        </div>
      </div>
    </div>
  );
}
