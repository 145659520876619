import FadeInSection from "./FadeInSection";

const actions = [
  {
    title: "Start With a Book",
    href: "https://www.startwithabook.org/",
    description:
      'To ward off the learning loss that many children experience over the summer, Start with a Book offers parents, caregivers, summer program staff and librarians lots of engaging ideas for getting kids hooked on reading, exploring and learning all summer long — and beyond.',
  },
  {
    title: "Code.org",
    href: "https://code.org/",
    description:
      "Code.org® is an education innovation nonprofit dedicated to the vision that every student in every school has the opportunity to learn computer science as part of their core K-12 education.",
  },
  {
    title: "Sciencebuddies",
    href: "https://www.sciencebuddies.org/",
    description: "Hands-on Science Resources for Home and School",
  },
  {
    title: "Bil Nye.com",
    href: "https://www.billnye.com/",
    description:
      "The official website of Bill Nye The Science Guy featuring books, shows, appearances, episode guides, and home demos.",
  },
  {
    title: "James Dyson Foundation",
    href: "https://www.jamesdysonfoundation.com/",
    description:
      "The James Dyson Foundation provides the materials and mentorship that budding inventors need, so that they can get hands-on with problems, think differently, and find solutions.",
  },
  {
    title: "Scholastic",
    href: "https://www.scholastic.com/parents/school-success/learn-at-home.html",
    description: "Leading provider of literacy curriculum, professional services, and classroom magazines, and a producer of educational and popular children's media",
  },
  {
    title: "Hello Ruby",
    href: "https://www.helloruby.com/",
    description: "Hello Ruby is the world’s most whimsical way to learn about technology, computing and coding. We are all about curiosity, playfulness and logic."
  },
  {
    title: "Musiclab",
    href: "https://musiclab.chromeexperiments.com/",
    description:
      "Chrome Music Lab is a website that makes learning music more accessible through fun, hands-on experiments. Many teachers have been using Chrome Music Lab as a tool in their classrooms to explore music and its connections to science, math, art, and more.",
  },
  {
    title: "Calacademy",
    href: "https://www.calacademy.org/ ",
    description: "The mission of the California Academy of Sciences is to regenerate the natural world through science, learning, and collaboration."
  },
  {
    title: "NASA",
    href: "https://www.nasa.gov/stem/ ",
    description: "Website offers unique STEM related opportunities hosted by NASA"
  },
  {
    title: "Scratch MIT",
    href: "https://scratch.mit.edu/ ",
    description: "Scratch is a programming language and an online community where children can program and share interactive media such as stories, games, and animation with people from all over the world."
  },
  {
    title: "Codemonkey",
    href: "https://www.codemonkey.com/",
    description: "CodeMonkey is an AWARD-WINNING online platform that teaches kids real coding languages like CoffeeScript and Python."
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GridList1() {
  return (
    <div className="-mt-12 divide-y divide-gray-200 overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 m-5">
      {actions.map((action, actionIdx) => (
        <FadeInSection>
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0
                ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                : "",
              actionIdx === 1 ? "sm:rounded-tr-lg" : "",
              actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
              actionIdx === actions.length - 1
                ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                : "",
              "relative group p-6 bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-rose-500"
            )}
          >
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <a href={action.href} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </a>
              </h3>
              <p className="mt-2 text-sm text-gray-500">{action.description}</p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        </FadeInSection>
      ))}
    </div>
  );
}
