export default function ContactForm() {
  return (
    <div>
      <form
        name="contact"
        method="post"
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div>
          <label htmlFor="first-name" className="block text-sm font-medium">
            First name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              required
              className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
            />
          </div>
        </div>
        <div>
          <label htmlFor="last-name" className="block text-sm font-medium ">
            Last name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              required
              className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
            />
          </div>
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
            />
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <label htmlFor="number" className="block text-sm font-medium ">
              Phone
            </label>
            <span id="phone-optional" className="text-sm text-warm-gray-500">
              Optional
            </span>
          </div>
          <div className="mt-1">
            <input
              type="text"
              name="number"
              id="phone"
              autoComplete="tel"
              className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
              aria-describedby="phone-optional"
            />
          </div>
        </div>
        {/* <div className="sm:col-span-2">
          <label htmlFor="subject" className="block text-sm font-medium ">
            Describe Yourself
          </label>
          <SelectMenu />
        </div> */}
        <div className="sm:col-span-2">
          <div className="flex justify-between">
            <label htmlFor="message" className="block text-sm font-medium ">
              Message (please describe youself within the message)
            </label>
            {/* <span id="message-max" className="text-sm text-warm-gray-500">
              Max. 500 characters
            </span> */}
          </div>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows={4}
              className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
              aria-describedby="message-max"
              required
            />
          </div>
        </div>
        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <button
            type="submit"
            className="inline rounded-md border border-transparent bg-rose-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
