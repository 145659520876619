import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

export default function About() {
  return (
    <div className="bg-background">
      <Navbar />
      <Header
        heading="Who Are We?"
        text="Our organization, which a group of 5 students founded, was created to inspire STEM literacy and excitement across Pennsylvania. We partner with local businesses and other STEM organizations to provide anyone, especially adolescents, with STEM opportunities, unlike anything they have experienced before. Please join us at our next event or check our events page for more information.
"
      />
      <FAQ />
      <Footer />
    </div>
  );
}
