import Navbar from "../components/Navbar";

import Footer from "../components/Footer";
import GridList1 from "../components/GridList1";
import Header from "../components/Header";
import GridList2 from "../components/GridList2";

export default function Resources() {
  return (
    <div className="bg-background">
      <Navbar />
      
      <Header
        heading="Resources K-6"
        text= "Check out our carefully curated resources for kindergarten to sixth-grade students."
      />
      <GridList1/>
      <Header
        heading="Resources 7-12"
        text="Check out our carefully curated resources for seventh to twelfth-grade students. "
        className="-mt-14"
      />
      <GridList2/>

      <Footer />
    </div>
  );
}
