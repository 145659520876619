import Navbar from "../components/Navbar";

import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoCloud from "../components/LogoCloud";

export default function Partners() {
  return (
    <div className="bg-background">
      <Navbar />
      <Header
        heading="Our Current Partners"
        text="A partner is any organization or business which supports our mission and works with our organization to inspire STEM literacy and excitement across Bucks County. They may do this by donating, hosting events, and or providing technology or other resources to us. We are currently looking for partners so if interested please reach out via the form on the Contact Us Page."
      />
      <LogoCloud/>
      <br></br>

   


      <Footer />
    </div>
  );
}
