import Footer from "../components/Footer";

export default function NotFound(){
    return (
        <div className="flex min-h-full flex-col bg-background lg:relative ">
        <div className="flex flex-grow flex-col">
          <main className="flex flex-grow flex-col">
            <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 pt-10 sm:pt-16">
                <a href="/" className="inline-flex">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-12 w-auto"
                    src="./assets/logo.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="my-auto flex-shrink-0 py-16 sm:py-32">
                <p className="text-base font-semibold text-rose-600">404</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
                <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
                <div className="mt-6">
                  <a href="/" className="text-base font-medium text-rose-600 hover:text-rose-500">
                    Go back home
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </main>
          
        </div>
      
        <div className="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:block lg:w-1/2 z-0">
          <img
            className="absolute inset-0 w-full h-[44.5rem] object-cover"
            src="./assets/Satellite Dish.jpg"
            alt=""
          />
        </div>
      <div className="lg:mt-32 md:mt-28 sm:mt-32">
        <Footer/>
      </div>

      
      </div>
    );
}