export default function TestimonialCard() {
  return (
    <div>
      <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
        {/* Testimonial card*/}
        <div className="relative overflow-hidden rounded-2xl pt-64 pb-10 shadow-xl">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="./assets/VR_Girl.jpg"
            alt=""
          />
          <div className="absolute inset-0 bg-rose-500 mix-blend-multiply" />
          <div className="absolute inset-0 bg-gradient-to-t from-rose-600 via-rose-600 opacity-90" />
          <div className="relative px-8">
            <blockquote className="mt-8">
              <div className="relative text-lg font-medium text-white md:flex-grow">
                <svg
                  className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-rose-400"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="relative">
                  Nothing in life is to be feared, it is only to be understood.
                  Now is the time to understand more, so that we may fear less
                </p>
              </div>

              <footer className="mt-4">
                <p className="text-base font-semibold text-rose-200">
                  Marie Curie
                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
}
