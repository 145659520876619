

export default function WebDevelopmentArticle() {
  return (
    <div className="relative overflow-hidden py-16">
      
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div
          className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          
        </div>
      </div>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-rose-600">
              Welcome
            </span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Web Devlopment Workshop
            </span>
          </h1>
          <br></br>
        </div>
        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
          {/* <div className="object-scale-down">
            <figure>
              <img
                className="w-full rounded-lg"
                src="./assets/webDevWorkshopFlyer.jpg"
                alt="Web Development Workshop"
              />
              <figcaption>
                Sagittis scelerisque nulla cursus in enim consectetur quam.
              </figcaption>
            </figure>
          </div> */}
          <br></br>
          <h2 className=" font-semibold text-rose-600">
            Class resources
          </h2>
          <br></br>
          <p>
            Check out the following resources used during the presentation and check out the other links for some extra information. 
            <ol>
              <li><a className="underline pt-5" href="https://replit.com/@SeanK6/Blog-For-Workshop#index.html">Blog Template</a></li>
              <li><a className="underline pt-5" href="https://blog.hubspot.com/website/website-development">Article by HubSpot on the basics of website development</a></li>
              <li><a className="underline pt-5" href="https://coder-coder.com/learn-web-development/">Article by Coder-Coder on how to learn web development</a></li>
              <li><a className="underline pt-5" href="https://bootcamp.berkeley.edu/resources/coding/learn-web-development/">Article by Berkeley University on how to learn web development</a></li>
            </ol>
          
          </p>
        </div>
      </div>
    </div>
  );
}
