import FadeInSection from "./FadeInSection";

export default function TestimonialText() {
  return (
    <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
      {/* Content area */}
      <FadeInSection>
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          On a mission to empower youth in STEM...
        </h2>
        <div className="mt-6 space-y-6 text-gray-500">
          <p className="text-lg">
            STEM is the future, so we believe in providing education and
            opportunity to students to get them ahead of the game. To accomplish
            this, we offer the resources to help them succeed and reach their
            aspirations. We aim to make it easy for those of all backgrounds to
            have access to STEM and its amazing benefits.
          </p>
          <p className="text-base leading-7">
            Although we come from different backgrounds, everyone involved with
            the founding and running of this organization is committed to
            improving the lives of those in Pennsylvania and making a
            difference in our communities. We, as students, recognize the
            challenge of finding opportunities to expand our learning outside of
            school, and to help fix this, we built this organization. Our goal is
            to inspire STEM literacy and excitement across Pennsylvania.
          </p>
          <p className="text-base leading-7">
            Please join us on this journey by coming to our next event. Don't
            let yourself or your child miss this opportunity! As always, if you
            have any questions do not hesitate and send us an email or reach out
            on our contact us page.
          </p>
        </div>
      </div>
      {/* <Stats/> */}
      <div className="mt-10">
        <a href="/about" className="text-base font-medium text-rose-500">
          Learn more about us &rarr;
        </a>
      </div>
      </FadeInSection>
      
      
    </div>
  );
}
