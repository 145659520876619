import FadeInSection from "./FadeInSection";

const actions = [
  {
    title: "American Association for the Advancement of Science",
    href: "https://www.aaas.org/ ",
    description: "A boldly inclusive, mobilized, and global scientific community that ignites, enables, and celebrates scientific excellence and science-informed decisions and actions."
  },
  {
    title: "Association for Women in Science",
    href: "https://awis.org/",
    description: "AWIS is a global network that inspires bold leadership, research, and solutions that advance women in STEM, spark innovation, promote organizational success and drive systemic change."
  },
  {
    title: "Codecademy",
    href: "https://www.codecademy.com/",
    description: "Empowering all people, regardless of where they are in their coding journeys, to continue to learn, grow, and make an impact on the world around them."
  },
  {
    title: "Firstinspires",
    href: "https://www.firstinspires.org/",
    description: "FIRST® inspires young people to be science and technology leaders and innovators by engaging them in exciting mentor-based programs that build science, engineering, and technology skills, that inspire innovation, and that foster well-rounded life capabilities including self-confidence, communication, and leadership."
  },
  {
    title: "Girls Who Code",
    href: "https://girlswhocode.com/", 
    description: "Girls Who Code is on a mission to close the gender gap in technology and to change the image of what a programmer looks like and does."
  },
  {
    title: "Khanacademy",
    href: "https://www.khanacademy.org/",
    description: "Learn for free about math, art, computer programming, economics, physics, chemistry, biology, medicine, finance, history, and more."
  },
  {
    title: "National Stem Honor Society",
    href: "https://nstem.org/",
    description: "Our mission is to inspire innovation and recognize student excellence from K to Career in the fields of Science, Technology, Engineering, and Mathematics."
  },
  {
    title: "Project Lead The Way",
    href: "https://www.pltw.org/",
    description: "Project Lead The Way provides transformative learning experiences for PreK-12 students and teachers across the U.S. We create an engaging, hands-on classroom environment and empower students to develop in-demand knowledge and skills they need to thrive."
  },
  {
    title: "Revolution Robotics Foundation",
    href: "https://revolutionrobotics.org/",
    description: "Revolution Robotics creates robotics kits, challenges, and competitions that develop critical 21st century work skills, while inspiring kids around the world to pursue degrees and careers in STEM."
  },
  {
    title: "STEM-Works",
    href: "http://stem-works.com/subjects",
    description: "A resource for teachers, mentors, parents, STEM professionals, volunteers, and everyone passionate about getting children eager to learn about science, technology, engineering, and math."
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GridList2() {
  return (
    <div className="-mt-12 divide-y divide-gray-200 overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 m-5">
      {actions.map((action, actionIdx) => (
        <FadeInSection>
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0
                ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                : "",
              actionIdx === 1 ? "sm:rounded-tr-lg" : "",
              actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
              actionIdx === actions.length - 1
                ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                : "",
              "relative group p-6 bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
            )}
          >
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <a href={action.href} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </a>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {action.description}
              </p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        </FadeInSection>
      ))}
    </div>
  );
}
