
export default function ArticleBodyOctoberTwentySeventh2024() {
  return (
    <div className="relative overflow-hidden py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
      <div
          className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative mb-4 px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-rose-600">
              Coming November 8th [Registration Open]
            </span>
            <span className="mt-4 block text-center text-6xl font-bold leading-tight tracking-wide text-gray-900 sm:text-5xl">
              STEM Detectives:  Mystery Mission at Arena STEM
            </span>
            <span className="mt-2 block text-center text-lg leading-8 tracking-tight text-gray-900 sm:text-2xl">
              $10 Entry to fundraise for a good cause!  Snacks will be provided.  Open to all ages K-4.
            </span>
          </h1>
          <div className="flex justify-center mt-4">
          <img className="w-1/2 h-auto mx-2 rounded-lg" src="./assets/logo.png" alt="stemepalogo" style={{ width: '100%', height: '100%' }} />
          </div>
          <p className="mt-8 text-xl leading-8 text-gray-500">
          <span className="font-bold">Breaking News!</span> A group of dastardly science criminals have stolen some of the most VALUABLE scientific secrets from the top-secret facility at <span className="font-bold">Arena STEM</span>, at <span className="underline font-bold">950 Industrial Blvd, Southampton PA, 6-8 PM.</span> STEM Enrichment of Pennsylvania is looking for a team of young, sharp-minded detectives to <span className="font-bold">crack the case</span> and save the day!  All kids from and outside of <span className="font-bold">Council Rock School District</span> are invited to this high-stakes mission, where they will explore mysteries using engaging science, technology, engineering, and math.  
            If you're looking for a fun and engaging, but most importantly, <span className="font-bold">educational</span> night for your young child, don't miss out on this amazing chance!  Secure your spot for the evening soon.  
            Click <a href="https://www.arenastempa.com/" className="underline">here</a> for more information about our partner, Arena STEM.  
          </p>
          <br></br>
          <div className="aspect-w-16 aspect-h-9">
          <span className="mt-2 block text-center text-5xl font-bold leading-tight tracking-wide text-gray-900 sm:text-4xl">
            Friday, November 8 Sign-Up
            </span>
            <br></br>
          <iframe
  title="Embedded registration form, link at bottom"
  src="https://docs.google.com/forms/d/e/1FAIpQLSdGbg3i-fUzZAoUZpAlKnqjYwZrOpM4Zi2DcAEiV6BqJGL1lg/viewform?usp=sf_link"
  className="w-full h-[600px] lg:h-[800px]"  // Adjust the height as needed
  frameBorder="0"
  marginHeight="0"
  marginWidth="0"
  style={{ width: '100%' }}  // Ensure the iframe takes full width
>
  Loading…
</iframe>
<br></br>

<br></br>

          </div>
        </div>
        
        <div className="flex justify-center mt-4">
          <br></br>
<img
  src="./assets/1-removebg-preview.png"
  alt="arenastemlogo"
  className="w-1/2 h-auto mx-2 rounded-lg"
  style={{ width: '60%', height: '60%' }}
/>
</div>



        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
        <br></br>
        <h2 className="font-bold text-rose-600 mt-10 text-3xl">Overview</h2>
          <p className="text-2xl leading-10">
          Unleash your inner detective and help us solve the STEM Mystery Mission! In this one-of-a-kind event, participants will venture through Arena STEM's expansive and broad facilities to tackle exciting, hands-on challenges at each station. At each stop, young detectives will uncover clues by applying different STEM skills, inching closer and closer to unraveling the grand culprits. From coding robotic cars to flying drones to cracking codes, every step will bring kids closer to saving the day—and learning STEM principles in the process.

</p>
          <p className="text-2xl leading-10">
          This event is catered mostly toward children from K-4, however, there are <span className="font-bold">no restrictions on participation</span>.  This event is hosted and supported directly by the Council Rock School District, and supervised by Council Rock high school students.    
          </p>
          <br></br>
          <img className="w-1/2 h-auto mx-2 rounded-lg" src="./assets/detectiveblack.png" alt="lego_mindstorms" style={{ width: '20%', height: '20%' }} />
<br></br>
<h2 className="font-bold text-rose-600 mt-10 text-3xl">What to Expect</h2>
<p className="text-2xl leading-10">
  <span className="underline font-semibold">LEGO Car Building and Coding:</span> Using the WeDo 2.0 kits, children will engage in both engineering and computer science, assembling their cars from physical LEGOs and coding them online using the intuitive tablet platform.
</p>
<p className="text-2xl leading-10">
  <span className="underline font-semibold">Interactive Wall Challenge:</span> At this station, kids will face an interactive wall full of digital puzzles and challenges that will require sharp thinking and teamwork to complete, unlocking clues with each successful puzzle.
</p>
<p className="text-2xl leading-10">
  <span className="underline font-semibold">Drones:</span> Kids will learn the basics of drone navigation and maneuver mini-drones through an obstacle course to uncover hidden clues. This hands-on experience gives a real-world application of physics and technology in an exciting, engaging format.
</p>
<p className="text-2xl leading-10">
  <span className="underline font-semibold">Puzzle Stations:</span> Scattered throughout the event, these stations will feature riddles, ciphers, and logic puzzles. Solving them requires careful teamwork and critical thinking to reveal essential clues.
</p>
<p className="text-2xl leading-10">
  <span className="underline font-semibold">Interactive Learning and Teamwork:</span> Throughout the night, students will be working and collaborating in groups based on age/grade, with cooperation and creativity heavily encouraged. Teamwork, leadership, and communication will all be developed.
</p>
<br></br>
<h2 className="font-bold text-rose-600 text-3xl">Arena STEM Classes and Arena</h2>
<p className="text-2xl leading-10">
  Embark on the journey of endless excitement, where young minds can explore different activity zones, including immersing into VR, crafting LEGO creations, piloting drones, designing self-built racing car tracks, and so much more!
  Students can also join an educational journey like no other in Arena Stem's Discovery Classes, where young minds will dive into interactive STEM courses covering everything including coding, LEGO robots, bioSTEM, drone dynamics, advanced robotics, and beyond!
</p>
<br></br>
<h2 className="font-bold text-rose-600 text-3xl">Join Us</h2>
<p className="text-2xl leading-10">
  Don’t miss this opportunity to engage in a unique STEM experience that combines fun with learning. Mark your calendars for November 8th and join us at Arena STEM.
</p>
<br></br>
<br></br>
        </div>
      </div>
    </div>
  );
}