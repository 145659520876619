export default function ArticleBodyMayTwentyNinth2024() {
  return (
    <div className="relative overflow-hidden py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
      <div
          className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative mb-4 px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-rose-600">
              Coming July 25th [Registration Closed]
            </span>
            <span className="mt-2 block text-center text-5xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              LEGO Racer STEM Night at Arena Stem
            </span>
            <span className="mt-2 block text-center text-lg leading-8 tracking-tight text-gray-900 sm:text-2xl">
              Free participation.  Food / Drink will be provided.  Open to everyone.
            </span>
          </h1>
          <div className="flex justify-center mt-4">
          <img className="w-1/2 h-auto mx-2 rounded-lg" src="./assets/logo.png" alt="stemepalogo" style={{ width: '100%', height: '100%' }} />
          </div>
          <p className="mt-8 text-xl leading-8 text-gray-500">
          <span className="font-bold">Arena STEM</span>, a local community center for youth science education, along with dedicated 501c3 nonprofit STEMEPA, are proud to announce an exciting, new collaboration. This <span className="font-bold">July 25th</span>, at <span className="underline font-bold">950 Industrial Blvd, Southampton PA, 6-8 PM. Subject to change.</span> Experience an exciting, competitive, and creative night of learning using Arena STEM's state-of-the-art facilities!
          Best of all, this event is <span className="underline font-bold">free of charge</span><span className="underline font-bold"> and open to everyone,</span> providing a unique opportunity for everyone to participate.  Don't miss out on this amazing chance to combine play with learning. Secure your spot for an evening of innovation, imagination, and inspiration! 
            Click <a href="https://www.arenastempa.com/" className="underline">here</a> for more information.  
          </p>
          <br></br>
          <div className="aspect-w-16 aspect-h-9">
          <span className="mt-2 block text-center text-5xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Thursday, July 25 Sign-Up
            </span>
            <br></br>
          <iframe
  title="Embedded registration form, link at bottom"
  src="https://docs.google.com/forms/d/e/1FAIpQLSe9gdkUqwiQ6nLpOyALdirbD_au0sEIPcZDB1yCuDn_DOaifA/viewform?usp=sf_link"
  className="w-full h-[600px] lg:h-[800px]"  // Adjust the height as needed
  frameBorder="0"
  marginHeight="0"
  marginWidth="0"
  style={{ width: '100%' }}  // Ensure the iframe takes full width
>
  Loading…
</iframe>
<br></br>
<span className="mt-2 block text-center text-5xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Friday, July 26 Sign-Up
            </span>
            <br></br>
          <iframe
  title="Embedded registration form, link at bottom"
  src="https://docs.google.com/forms/d/e/1FAIpQLScYUpTUULMHYndlB1ivbCms0o7f5yx7yEgrNvAfPGxVOzTkrA/viewform"
  className="w-full h-[600px] lg:h-[800px]"  // Adjust the height as needed
  frameBorder="0"
  marginHeight="0"
  marginWidth="0"
  style={{ width: '100%' }}  // Ensure the iframe takes full width
>
  Loading…
</iframe>
<br></br>

          </div>
        </div>
        
        <div className="flex justify-center mt-4">
          <br></br>
<img
  src="./assets/1-removebg-preview.png"
  alt="arenastemlogo"
  className="w-1/2 h-auto mx-2 rounded-lg"
  style={{ width: '60%', height: '60%' }}
/>
</div>



        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
        <br></br>
        <h2 className=" font-semibold text-rose-600">Overview</h2>
          <p>
          Join us for LEGO Racer STEM Night, where young minds will have the chance to build and code their own LEGO cars using the <a href="https://education.lego.com/en-us/products/lego-education-wedo-2-0-core-set/45300/" className="underline">LEGO® MINDSTORMS® Education EV3 & LEGO® Education WeDo 2.0 (click for more info) </a>. This hands-on event brings out creativity and teamwork, developing STEM skills in a fun and engaging environment. Kids will then get to race their custom creations on a tricky obstacle course, learning all about engineering, technology, and problem-solving along the way.
          </p>
          <p>
          This event is catered mostly towards elementary school students, however, there are <span className="font-bold">no restrictions on participation</span>.  This event is hosted and supported directly by the Council Rock School District, and supervised by Council Rock high school students.    
          </p>
          <br></br>
          <img className="w-1/2 h-auto mx-2 rounded-lg" src="./assets/lego_mindstorms.png" alt="lego_mindstorms" style={{ width: '20%', height: '20%' }} />
          <br></br>
          <h2 className=" font-semibold text-rose-600">What to Expect</h2>
          <p>
            <span className="underline ">LEGO Car Building and Coding:</span> Using the WeDo 2.0 kits, children will get to engage in both engineering and computer science, assembling their cars from physical LEGOs and coding them online using the intuitive tablet platform. 
            </p>
          <p>
          <span className="underline">Obstacle Course Challenge:</span> After building and coding their cars, kids will navigate an exciting obstacle course designed to test their creations' agility and functionality.  The goal of the competition is to find the design that is able to traverse the rugged terrain with the most ease.  However, bonus consideration will be given to appearance and innovation.
          </p>
          <p>
          <span className="underline ">Interactive Learning:</span> Throughout the night, students will be working and collaborating in groups based on age / grade, with cooperation and creativity heavily encouraged.  Teamwork, leadership, and communication will all be developed.  
          </p>
          <br></br>
        <h2 className=" font-semibold text-rose-600">Arena STEM Classes and Arena</h2>
          <p>      
          Embark on the journey of endless excitement, where young minds can explore different activity zones, including immersing into VR, crafting LEGO creations, piloting drones, designing self-built racing car tracks, and so much more! 
Students can also join an educational journey like no other in Arena Stem's Discovery Classes, where young minds will dive into interactive STEM courses covering everything including coding, LEGO robots, bioSTEM, drone dynamics, advanced robotics, and beyond!
          </p>
          <br></br>
          <h2 className=" font-semibold text-rose-600">Join Us</h2>
          <p>
          Don’t miss this opportunity to engage in a unique STEM experience that combines fun with learning. Mark your calendars for July 25th and join us at Arena STEM.
          </p>
          <br></br>
          <br></br>
          <h2 className=" font-semibold text-rose-600">See Elizabeth and Sahana, two STEMEPA members, building their own LEGO Racecar below!</h2>
          <br></br>
           <div className="object-scale-down">
            <figure>
              <img
                className="w-full rounded-lg"
                src="./assets/elizabeth_sahana_lmfao.jpg"
                alt="Example Car"
                style={{ width: '40%', height: '40%' }} 
              />
              <figcaption>
              </figcaption>
            </figure>
          </div> 

          <br></br>
          <h2 className=" font-semibold text-rose-600">Why is this course important?</h2>
          <br></br>
          <p>
            To learn more about Arena STEM, view the Discovery Map listed below, in which Arena STEM's science curriclum is fully laid out.
          </p>
          <br></br>
          <div className="object-scale-down">
          <figure>
            <img
              className="w-full rounded-lg h-1/3"
              src="./assets/arenastem [MConverter.eu].jpg"
              alt="Web Development Workshop"
              style={{ width: '30%', height: '30%' }} 
            />
           
          </figure>
        </div>
        </div>
      </div>
    </div>
  );
}